import { forwardRef } from 'react';
import Slide from '../global/Slide';
import ReactPlayer from 'react-player/youtube';

import styles from './Applications.module.css';

const content = [
  {
    title: 'Icons / Stickers / Emojis',
    caption: 'Animated icons, stickers, and emojis have become synonymous with modern chat platforms. They help us convey our human emotions across our digital channels.',
    captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
  },
  {
    title: 'User interfaces',
    caption: 'From mobile apps to connected fridges and game HUDs to in-car dashboards – UIs are common place in our lives, and can be made better with animations that inform, guide, and engage.',
    captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
  },
  {
    title: 'Hero moments',
    caption: 'Guiding users through an onboarding process or celebrating an in-app accomplishment – animated hero moments make products stand out.',
    captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
  },
  {
    title: 'Characters',
    caption: 'Mascots, cartoons, animojis, companions, heroes, and villains. Characters feature across our brands, products, episodes, and games.',
    captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
  },
  {
    title: 'Data visualisation',
    caption: 'In a data-driven world, animation can help us better consume and shape narratives around swathes of information.',
    captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
  },
]

const Applications = forwardRef((_, ref) => (
  <Slide ref={ref} className={styles.applications}>
    <h3>The role of animation</h3>
    <h2>The opportunities are far reaching...</h2>
    <div style={{ width: '100%', maxWidth: '1440px' }}>
      <div className={styles.playerWrapper}>
        <ReactPlayer
          className={styles.reactPlayer}
          url="https://www.youtube.com/watch?v=1kn6hL-8ucE"
          controls
          playing
          muted
          loop
          width="100%"
          height="100%"
        />
      </div>
    </div>
    <div className={styles.copyContainer}>
      {content.map(item => (
        // <Expandable 
        //   className={styles.calloutVert} 
        //   key={item.title}
        //   modalHeader={item.title}
        //   modalCopy={item.captionLong}
        // >
        //   <h4>{item.title}</h4>
        //   <p>{item.caption}</p>
        // </Expandable>
        <div key={item.title} className={styles.calloutVert}>
          <h4>{item.title}</h4>
          <p>{item.caption}</p>
        </div>
      ))}
    </div>
  </Slide>
))

export default Applications;