import { ComponentProps, useState, useEffect, useRef } from "react";
import { useRive, useStateMachineInput } from "rive-react";
import styles from "./Animations.module.css";

interface Props {
  play: boolean;
  onFlameClick: (index: number) => void;
}

const NeoMenu = ({
  play,
  onFlameClick,
  ...rest
}: Props & ComponentProps<"div">) => {
  const params = {
    src: "/neostream.riv",
    stateMachines: "State Machine 4",
    autoplay: true,
    onload: () => {
      // const inputs = rive.current.stateMachineInputs('State Machine 4');
      // flameInput.current = inputs[0];
    },
  };

  const ref = useRef<HTMLDivElement>(null);
  const { rive, RiveComponent } = useRive(params);
  const [activeFlame, setActiveFlame] = useState<number | null>(null);
  const flameInput = useStateMachineInput(rive, "State Machine 4", "flame");

  useEffect(() => {}, []);

  function focusFlame(flameIndex: number) {
    if (activeFlame === flameIndex) {
      return;
    }
    if (flameInput) {
      flameInput.value = flameIndex;
      setActiveFlame(flameIndex);
    }
  }

  function unfocusFlame(flameIndex: number) {
    if (activeFlame === flameIndex) {
      if (flameInput) flameInput.value = 0;
      setActiveFlame(null);
    }
  }

  return (
    <div ref={ref} {...rest}>
      <div className={styles.square}>
        <div className={styles.neo}>
          {rive &&
            flames.map((flame) => (
              <div
                key={flame.navIndex}
                className={styles.flameButton}
                style={{ top: `${flame.y * 100}%`, left: `${flame.x * 100}%` }}
              >
                <h4
                  className={
                    [1, 5].includes(flame.i)
                      ? styles.labelBelow
                      : styles.labelAbove
                  }
                  style={{ opacity: flame.i === activeFlame ? "1" : "0" }}
                >
                  {flame.label}
                </h4>
                <button
                  onMouseEnter={() => focusFlame(flame.i)}
                  onMouseLeave={() => unfocusFlame(flame.i)}
                  onClick={() => activeFlame && onFlameClick(flame.navIndex)}
                />
              </div>
            ))}
          <RiveComponent />
        </div>
      </div>
    </div>
  );
};

export default NeoMenu;

const flames = [
  {
    i: 1,
    x: 0.26,
    y: 0.77,
    navIndex: 1,
    label: "Problem",
  },
  {
    i: 2,
    x: 0.172,
    y: 0.475,
    navIndex: 2,
    label: "Solution",
  },
  {
    i: 3,
    x: 0.475,
    y: 0.257,
    navIndex: 5,
    label: "Market",
  },
  {
    i: 4,
    x: 0.78,
    y: 0.475,
    navIndex: 7,
    label: "Roadmap",
  },
  {
    i: 5,
    x: 0.68,
    y: 0.77,
    navIndex: 9,
    label: "Team",
  },
];
