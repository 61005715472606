import { forwardRef } from 'react';
import Rive, { Fit, Layout } from 'rive-react';
import Slide from '../global/Slide';

import styles from './Strategy.module.css';

const content = [
  {
    heading: 'Set up our strike force',
    animation: 'strike_force.riv',
    items: [
      {
        title: 'Form a Creator Relations team',
        caption: 'We will need creative and technical evangelists from our web, product, and gaming markets. They will explain the benefits of the product for each segment while nurturing an active community by teaching, hosting events, maintaining documentation, writing case studies, and building examples.',
        captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
      },
      {
        title: 'Advocacy program',
        caption: 'Advocates are Rive community members that run local groups and host meetups. They get access to unique resources, recognition in our community, a free Pro plan, cool swag, and a direct line to our product team.',
        captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
      },
      {
        title: 'Community',
        caption: 'The community demonstrates the breadth of content that can be created with Rive and allows our different market segments to gather and grow together.',
        captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
      },
    ]
  },
  {
    heading: 'Position appropriately',
    animation: 'rollercoaster.riv',
    items: [
      {
        title: 'Mainstream market has different expectations',
        caption: 'Our mainstream market will be more skeptical and pragmatic than our early adopters. They will want to see that other companies have used Rive successfully and need compelling reasons to adopt the platform.',
        captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
      },
      {
        title: 'Market share',
        caption: 'We will need to demonstrate that Rive is acquiring market share with logos and endorsements from their peers (partners and competitors). We will also need to show the proliferation of Rive graphics in well-established products, games, and websites.',
        captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
      },
      {
        title: 'ROI',
        caption: 'We will want to demonstrate specific metrics on ROI, standards certification, and security of the Rive runtimes.',
        captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
      },
    ]
  },
  {
    heading: 'Distribute through the right channels',
    animation: 'flying_car.riv',
    items: [
      {
        title: 'Partnerships',
        caption: 'Partner with major app and game engines to present the benefits of Rive to their customers directly. We are already well-positioned with Google, Defold, and Samsung; we need to expand this to all others (Unity, Epic, Roblox, Apple, etc.).',
        captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
      },
      {
        title: 'Sponsorships',
        caption: 'Instead of creating ads or content that has to find its way to potential users, we will be in the places they are already watching (e.g. YouTube, Dribbble, ArtStation).',
        captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
      },
      {
        title: 'Watermark',
        caption: 'The "Made with Rive" watermark appears on all rendered videos for free users, making it easy to share their creations on social media while raising awareness of the Rive brand.',
        captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
      },
      {
        title: 'Education program',
        caption: 'Special pricing for students and teachers.',
        captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
      },
    ]
  },
]

const MarketStrategy = forwardRef((_, ref) => {
  return (
    <Slide ref={ref} className={styles.strategy}>
      <h3>Phase 2</h3>
      <h2>Implement our go-to-market strategy</h2>
      <h4>Once the product offering is ready, we go after the early majority of our mainstream market.</h4>
      <div className={styles.threeUp}>
        {content.map(section => (
          <div key={section.heading}>
            <div className={styles.ratio}>
              <Rive className={styles.featureAnimation} src={section.animation} layout={new Layout({ fit: Fit.Cover })} />
            </div>
            <h3>{section.heading}</h3>
            <div className={styles.divider}/>
            {section.items.map(item => (
              // <Expandable 
              //   key={item.title} 
              //   className={styles.calloutVert}
              //   modalHeader={item.title}
              //   modalCopy={item.captionLong}
              // >
              //   <h4>{item.title}</h4>
              //   <p>{item.caption}</p>
              // </Expandable>
              <div key={item.title} className={styles.calloutVert}>
                <h4>{item.title}</h4>
                <p>{item.caption}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Slide>
  )
})

export default MarketStrategy;