import { useRef, useEffect, useState } from 'react';
import { useWindowSize } from '../hooks.js';
import { Rive, Layout } from 'rive-js';

const RiveIcon = ({ file, isHovering }) => {
  const rive = useRef(null);
  const canvas = useRef(null);
  const animationContainer = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const hasIntroed = useRef(null);
  const size = useWindowSize();

  useEffect(() => {
    rive.current = new Rive({
      src: file ?? 'icons/icon_rocket.riv',
      canvas: canvas.current,
      animations: 'init',
      layout: new Layout('contain', 'center')
    });
    rive.current.on('stop', () => {
      rive.current.play(hasIntroed.current ? 'idle-hover' : 'idle-dot');
      hasIntroed.current = true
    });
    const observer = new IntersectionObserver(([entry]) => setIsVisible(entry.isIntersecting));
    observer.observe(animationContainer.current);
    return () => {
      rive.current.stop();
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isHovering) {
      rive.current.stop();
      rive.current.play('init');
    } else if (hasIntroed.current) {
      rive.current.stop();
      rive.current.play('idle-dot');
    }
  }, [isHovering])

  useEffect(() => {
    isVisible ? rive.current.play() : rive.current.pause();
  }, [isVisible])

  useEffect(() => {
    const bounds = animationContainer.current?.getBoundingClientRect();
    canvas.current.width = bounds.width;
    canvas.current.height = bounds.height;
    rive.current.layout = new Layout({ fit: 'contain', alignment: 'contain' })
  }, [size])

  return (
    <div 
      ref={animationContainer} 
      style={{ width: '100%', height: '100%' }}
    >
      <canvas ref={canvas} />
    </div>
  );
}

export default RiveIcon;