import { ComponentProps, useEffect } from 'react';
import { useRive, useStateMachineInput } from 'rive-react';

interface Props {
  play: boolean
}

const HouseCards = ({ play, ...rest }: Props & ComponentProps<'div'>) => {
  const params = {
    src: '/cards-branded.riv',
    stateMachines: 'Series A Alt 2',
    autoplay: true,
  }

  const { rive, RiveComponent } = useRive(params);
  const input = useStateMachineInput(rive, 'Series A Alt 2', 'Collapse');

  useEffect(() => {
    
  }, []);

  function collapse() {
    if (input) input.fire();
  }

  return <RiveComponent {...rest} onClick={collapse} />;
};

export default HouseCards;
