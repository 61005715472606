import { ComponentProps, useEffect, useState } from 'react';
import { useRive, useStateMachineInput } from 'rive-react';
import { join, insertIf } from '../utils';
import styles from './Animations.module.css';

const SkillsAnimation = () => {
  const { rive, RiveComponent } = useRive({
    src: '/skills.riv',
    stateMachines: 'skill-controller',
    autoplay: true,
  });

  const levelInput = useStateMachineInput(rive, 'skill-controller', 'level');
  const [level, setLevel] = useState<number>(0);

  function isLevel(value: number) {
    return level === value;
  }

  useEffect(() => {
    if (levelInput) {
      levelInput.value = level;
    }
  }, [level, levelInput]);

  return (
    <div>
      <div className={styles.container}>
        <RiveComponent className={styles.visual} />
      </div>
      <div className={styles.buttonCluster}>
        {['Beginner', 'Intermediate', 'Expert'].map((level, index) => (
          <LevelButton
            key={level}
            label={level}
            active={isLevel(index)}
            onClick={() => setLevel(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default SkillsAnimation;

type LevelButtonProps = {
  label: string;
  active: boolean;
};

const LevelButton = ({
  label,
  active,
  ...rest
}: LevelButtonProps & ComponentProps<'button'>) => (
  <button
    className={join([
      styles.levelButton,
      ...insertIf(active, styles.levelButtonActive),
    ])}
    {...rest}
  >
    {label}
  </button>
);
