import { forwardRef } from 'react';
import RiveLogo from '../global/RiveLogo';

import styles from './Close.module.css';

const Close = forwardRef((props, ref) => {
  return (
    <div ref={ref} className={styles.close}>
      <div className={styles.closeContainer}>
        <div className={styles.logo}>
          <RiveLogo height={50} />
        </div>
        <div>
          <h1>Thank you</h1>
          <div className={styles.linksContainer}>
            <div>
              <h3>Links</h3>
              <CaptionedLink 
                title="Learn more"
                link="https://rive.app"
                caption="Visit our site to learn more about Rive"
              />
              <CaptionedLink 
                title="Visit the community"
                link="https://rive.app/community"
                caption="See what others are creating with Rive"
              />
              <CaptionedLink 
                title="Check out our resources"
                link="https://rive.app/resources"
                caption="Documentation, tutorials, and more!"
              />
            </div>
            <div>
              <h3>Connect</h3>
              <CaptionedLink 
                title="Twitter"
                link="https://twitter.com/rive_app"
                caption="Get the latest announcements"
              />
              <CaptionedLink 
                title="Discord"
                link="https://discord.com/invite/FGjmaTr"
                caption="Join the conversation"
              />
              <CaptionedLink 
                title="YouTube"
                link="https://www.youtube.com/channel/UCPal2R1FxwRTPylhP_7ofEg"
                caption="Check out our tutotials"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default Close;

const CaptionedLink = ({ title, link, caption }) => (
  <div className={styles.captionedLink}>
    <a 
      href={link}
      target="_blank" 
      rel="noopener noreferrer"
    >
      {title}
    </a>
    <p>{caption}</p>
  </div>
)