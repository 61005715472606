import { forwardRef, useState } from 'react';
import Slide from '../global/Slide';
import RiveIcon from '../global/RiveIcon';
import SolutionAnimation from '../animations/SolutionAnimation';

import styles from './Solution.module.css';

const content = [
  {
    animation: 'Editor',
    icon: 'icons/icon_rocket.riv',
    caption: 'Empower designers with a purpose-built animation editor with advanced functionality.'
  },
  {
    animation: 'Team',
    icon: 'icons/icon_cursors.riv',
    caption: 'Enable teams and organisations to seamlessly collaborate and iterate on their work.'
  },
  {
    animation: 'Runtime',
    icon: 'icons/icon_gears.riv',
    caption: 'Provide open-source runtimes for a plethora of platforms to deploy to any device.'
  },
  {
    animation: 'Community',
    icon: 'icons/icon_community.riv',
    caption: 'Foster a community to inspire and share content that raises the bar for our digital product experiences.'
  },
]

const Solution = forwardRef((_, ref) => {

  const [hoverIndex, setHoverIndex] = useState(null);

  return (
    <Slide ref={ref} className={styles.solution}>
      <div className={styles.copyContainer}>
        <div className={styles.copy}>
          <h3>Our solution</h3>
          <h2>Provide an end-to-end animation solution fit for modern platforms</h2>
          {content.map((item, index) => (
            <div
              key={`solution-item-${index}`}
              className="callout"
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              <div className={styles.iconContainer}>
                <RiveIcon file={item.icon} isHovering={index === hoverIndex} />
              </div>
              <p>{item.caption}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.rivContainer}>
        <div className={styles.ratio}>
          <SolutionAnimation 
            className={styles.animation}
            activeCaption={
              hoverIndex != null
                ? content[hoverIndex].animation
                :  null}
            />
        </div>
      </div>
    </Slide>
  )
})

export default Solution;