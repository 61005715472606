import { forwardRef } from 'react';
import Slide from '../global/Slide';
import Expandable from '../global/Expandable';

import styles from './Team.module.css';

const content = {
  founders: [
    {
      name: 'Luigi Rosso',
      title: 'Founder',
      image: '/team/luigi.jpeg',
      caption: 'Luigi started programming in high-school in the late 90s building his own game engines and modding popular titles like Quake 2 and Tribes. This led to his first job with Diamond Multimedia, building tools for their online gaming community.',
      captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
    },
    {
      name: 'Guido Rosso',
      title: 'Founder',
      image: '/team/guido.jpg',
      caption: 'Guido landed his first job by winning an online design competition in the late 90s for a newly formed gaming division of Diamond Multimedia. The twins decided from that moment on that Guido would be the designer and Luigi the developer.',
      captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
    },
  ],
  engineers: [
    {
      name: 'Umberto Sonnino',
      title: 'Software Engineer',
      caption: null,
      captionLong: null,
    },
    {
      name: 'Max Talbot',
      title: 'Software Engineer',
      caption: null,
      captionLong: null,
    },
    {
      name: 'Arthur Vivian',
      title: 'Software Engineer',
      caption: null,
      captionLong: null,
    },
    {
      name: 'Jill Casey',
      title: 'Lead Data Analyst',
      caption: null,
      captionLong: null,
    },
  ],
  designers: [
    {
      name: 'Juan Carlos Cruz Santana',
      title: 'Animator',
      caption: null,
      captionLong: null,
    },
    {
      name: 'Robert Haynie',
      title: 'Artist',
      caption: null,
      captionLong: null,
    },
    {
      name: 'Alex Gibson',
      title: 'Product Designer',
      caption: null,
      captionLong: null,
    },
    {
      name: 'Dante Brett-Holt',
      title: 'Designer',
      caption: null,
      captionLong: null,
    },
  ]
}

const Team = forwardRef((_, ref) => {
  return (
    <Slide ref={ref} className={styles.team}>
      <h3>The Rive team</h3>
      <div className={styles.header}>
        <h4> Guido and Luigi ran a <a href="http://guidorosso.com/#/archetype/">leading design/dev creative agency</a> in San Francisco (acquired by Intel), which specialized in interactive motion graphics. See <a href="http://guidorosso.com/">some of the projects</a> they've worked on together over the past 20+ years. One of their first clients in the US was Adobe, with whom they developed Adobe Kuler and the Flash UI components that shipped with CS3.</h4> 
        <div className={styles.founders}>
          {content.founders.map(founder => (
            <TeamMember key={founder.name} content={founder} />
          ))}
        </div>
      </div>
      <div className={styles.departmentHeader}>
        <h6>Engineering</h6>
        <h6>Design</h6>
      </div>
      <div className={styles.departments}>
        <div className={styles.engineers}>
          {content.engineers.map(engineer => (
            <TeamMember key={engineer.name} content={engineer} />
          ))}
        </div>
        <div className={styles.designers}>
          {content.designers.map(designer => (
            <TeamMember key={designer.name} content={designer} />
          ))}
        </div>
      </div>
      <h6>Backed by</h6>
      <div className={styles.investors}>
        <img src="/andreessenhorowitz.svg" alt="" />
        <img src="/unusual-ventures.svg" alt="" />
      </div>
    </Slide>
  )
})

export default Team;

const TeamMember = ({ content }) => (
  <div className={styles.teamMember}>
    {content.image && <img className={styles.image} src={content.image} alt="Team member"/>}
    {/* <Expandable
      modalHeader={content.name}
      modalCopy={content.captionLong}
    > */}
      <div>
        <h2>{content.name}</h2>
        <h4>{content.title}</h4>
        {content.caption && window.innerWidth > 768 && <p>{content.caption}</p>}
      </div>
    {/* </Expandable> */}
  </div>
);
