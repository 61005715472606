import { ComponentProps, useEffect } from 'react';
import { useRive, useStateMachineInput } from 'rive-react';

interface Props {
  play: boolean
}

const RiveMnemonic = ({ play, ...rest }: Props & ComponentProps<'div'>) => {
  const params = {
    src: '/rive-mnemonic.riv',
    stateMachines: 'Controller',
    autoplay: true,
  }

  const { rive, RiveComponent } = useRive(params);
  const revealTrigger = useStateMachineInput(rive, 'Controller', 'reveal');

  useEffect(() => {
    if (revealTrigger) {
      revealTrigger.fire();
    };
  }, [play, revealTrigger]);

  return <RiveComponent {...rest} onClick={() => revealTrigger!.fire()} />;
};

export default RiveMnemonic;
