import React, { useState, useEffect, useRef } from 'react';
import { useRive, useStateMachineInput } from 'rive-react';
import styles from './RiveUI.module.css';

type Props = {
  min?: number;
  max?: number;
  initialValue?: number;
  onValueChange: (value: number) => void;
};

const RiveSlider = ({
  min = 0,
  max = 100,
  initialValue = 50,
  onValueChange,
}: Props) => {
  const { rive, RiveComponent: Thumbnail } = useRive({
    src: '/rive-slider-thumb.riv',
    stateMachines: 'Controller',
    autoplay: true,
  });

  const hoverInput = useStateMachineInput(rive, 'Controller', 'isHovered');
  const grabInput = useStateMachineInput(rive, 'Controller', 'isGrabbed');

  const [value, setValue] = useState<number>(initialValue);
  const sliderRef = useRef<HTMLDivElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);
  const bounds = useRef<DOMRect | null>(null);

  useEffect(() => {
    if (sliderRef.current) {
      bounds.current = sliderRef.current.getBoundingClientRect();
    }
    setInitialThumbPosition();
  }, []);

  useEffect(() => {
    onValueChange(value);
  }, [value]);

  function setHover(value: boolean) {
    if (hoverInput) {
      hoverInput.value = value;
    }
  }

  function setGrab(value: boolean) {
    if (grabInput) {
      grabInput.value = value;
    }
  }

  // Mouse event handlers
  function onMouseDown(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    setGrab(true);
    document.body.style.cursor = 'grabbing';
    document.body.style.userSelect = 'none';
    thumbRef.current!.style.cursor = 'grabbing';
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);
  }

  function handleMouseMove(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (bounds.current) {
      const maxWidth = bounds.current.width;
      const xPos = e.clientX - bounds.current.left;
      const xClamped = Math.min(Math.max(xPos, 0), maxWidth);
      const mappedValue = map(xClamped, 0, maxWidth, min, max);
      thumbRef.current!.style.transform = `translateX(${xClamped}px)`;
      trackRef.current!.style.transform = `scaleX(${xClamped / maxWidth})`;
      setValue(mappedValue);
    }
  }

  function handleMouseUp(e: MouseEvent) {
    setGrab(false);
    // @ts-ignore-next-line
    if (!thumbRef.current?.contains(e.target)) {
      setHover(false);
    }
    document.body.style.cursor = 'auto';
    document.body.style.userSelect = 'auto';
    thumbRef.current!.style.cursor = 'grab';
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('mousemove', handleMouseMove);
  }

  function onTouchDown(e: React.TouchEvent) {
    e.preventDefault();
    e.stopPropagation();
    setGrab(true);
    setHover(true);
    document.body.style.userSelect = 'none';
    document.addEventListener('touchend', handleTouchEnd);
    document.addEventListener('touchmove', handleTouchMove);
  }

  function handleTouchMove(e: TouchEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (bounds.current) {
      const maxWidth = bounds.current.width;
      const xPos = e.touches[0].clientX - bounds.current.left;
      const xClamped = Math.min(Math.max(xPos, 0), maxWidth);
      const mappedValue = map(xClamped, 0, maxWidth, min, max);
      thumbRef.current!.style.transform = `translateX(${xClamped}px)`;
      trackRef.current!.style.transform = `scaleX(${xClamped / maxWidth})`;
      setValue(mappedValue);
    }
  }

  function handleTouchEnd() {
    setGrab(false);
    setHover(false);
    document.body.style.userSelect = 'auto';
    document.removeEventListener('touchend', handleTouchEnd);
    document.removeEventListener('touchmove', handleTouchMove);
  }

  function setInitialThumbPosition() {
    if (bounds.current) {
      const maxWidth = bounds.current.width;
      const mappedValue = map(initialValue, min, max, 0, maxWidth);
      thumbRef.current!.style.transform = `translateX(${mappedValue}px)`;
      trackRef.current!.style.transform = `scaleX(${mappedValue / maxWidth})`;
    }
  }

  function map(v: number, l1: number, h1: number, l2: number, h2: number) {
    return l2 + ((h2 - l2) * (v - l1)) / (h1 - l1);
  }

  return (
    <div ref={sliderRef} className={styles.RiveSliderContainer}>
      <div className={styles.Rail}>
        <div ref={trackRef} />
      </div>
      <div ref={thumbRef} className={styles.Thumb}>
        <Thumbnail
          onMouseDown={onMouseDown}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => {
            if (grabInput) grabInput.value === false && setHover(false);
          }}
          onTouchStart={onTouchDown}
        />
      </div>
    </div>
  );
};

export default RiveSlider;
