import { ComponentProps } from 'react';
import { useRive, useStateMachineInput } from 'rive-react';
import styles from './Animations.module.css';

const BearAnimation = () => {
  const { rive, RiveComponent } = useRive({
    src: '/bear.riv',
    stateMachines: 'State Machine 1',
    autoplay: true,
  });

  const correctInput = useStateMachineInput(rive, 'State Machine 1', 'Correct');
  const incorrectInput = useStateMachineInput(rive, 'State Machine 1', 'Incorrect');

  function answerCorrect() {
    if (correctInput) {
      correctInput.fire();
    }
  }

  function answerIncorrect() {
    if (incorrectInput) {
      incorrectInput.fire();
    }
  }

  return (
    <div>
      <div className={styles.container}>
        <RiveComponent className={styles.visual} />
      </div>
      <div className={styles.AnswerCluster}>
        <AnswerButton
          label="Correct"
          onClick={answerCorrect}
        />
        <AnswerButton
          label="Incorrect"
          onClick={answerIncorrect}
        />
      </div>
    </div>
  );
};

export default BearAnimation;

type AnswerButtonProps = {
  label: string;
};

const AnswerButton = ({
  label,
  ...rest
}: AnswerButtonProps & ComponentProps<'button'>) => (
  <button
    className={styles.AnswerButton}
    {...rest}
  >
    {label}
  </button>
);
