import { forwardRef } from 'react';
import Slide from '../global/Slide';
import ReactPlayer from 'react-player/youtube';
import SkillsAnimation from '../animations/SkillsAnimation';
import SeaLevelAnimation from '../animations/SeaLevelAnimation';
import BearAnimation from '../animations/BearAnimation';

import styles from './StateMachine.module.css';

const StateMachine = forwardRef((props, ref) => (
  <Slide ref={ref} className={styles.stateMachine}>
    <h3>Our latest hero feature</h3>
    <h2>State Machine</h2>
    <p>The State Machine is a visual way to build interactive motion graphics without code.  It empowers designers while simplifying implementation for engineers, further bridging the gap between the two fields.</p>
    <div style={{ width: '100%', maxWidth: '1440px' }}>
      <div className={styles.playerWrapper}>
        <ReactPlayer
          className={styles.reactPlayer}
          url={`https://www.youtube.com/watch?v=ObeMgfHbgCQ`}
          width="100%"
          height="100%"
          controls={false}
          playing
          muted
          loop
        />
      </div>
    </div>
    <h4>Examples</h4>
    <div className={styles.examples}>
      <SkillsAnimation />
      <SeaLevelAnimation />
      <BearAnimation />
    </div>
  </Slide>
))

export default StateMachine;