
export const YouTubeIcon = ({ color = 'white' }) => (
  <div style={{ marginRight: '6px' }}>
    <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M20.201 0.638084C15.0856 -0.212695 9.865 -0.212695 4.75085 0.638084C1.82582 1.24982 0 3.13771 0 5.59983V14.3503C0 16.8499 1.82462 18.7133 4.80005 19.3508C7.34539 19.7785 9.9199 19.9965 12.5005 20C15.0892 20 17.672 19.7867 20.2256 19.3625C23.126 18.8129 25.001 16.8629 25.001 14.362V5.61156C25.001 3.12481 23.126 1.17482 20.201 0.638114V0.638084ZM16.5764 10.5628L10.3267 14.3128H10.3255C10.2283 14.3726 10.1146 14.4031 10.0009 14.4007C9.89194 14.3995 9.7853 14.3738 9.68802 14.3257C9.48763 14.2038 9.3681 13.9847 9.37512 13.7503V6.25023C9.37746 6.02523 9.49582 5.81663 9.68802 5.70062C9.88724 5.59398 10.1263 5.59398 10.3255 5.70062L16.5752 9.45066C16.7592 9.56785 16.8705 9.77058 16.8705 9.9874C16.8705 10.2054 16.7592 10.4081 16.5752 10.5253L16.5764 10.5628Z" 
        fill={color}
      />
    </svg>
  </div>
)

export const WebIcon = ({ color = 'white' }) => (
  <div style={{ marginRight: '6px' }}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M9.99989 0.000218274C7.34762 0.000218274 4.80445 1.05364 2.92849 2.92871C1.05344 4.8046 0 7.34784 0 10.0001C0 12.6524 1.05342 15.1955 2.92849 17.0715C4.80439 18.9466 7.34762 20 9.99989 20C12.6522 20 15.1953 18.9466 17.0713 17.0715C18.9463 15.1956 19.9998 12.6524 19.9998 10.0001C19.9972 7.34872 18.9421 4.80707 17.0678 2.93198C15.1928 1.05781 12.6513 0.00261823 9.99967 0L9.99989 0.000218274ZM1.8716 10.9095H4.5699C4.62274 13.1195 5.1844 15.2876 6.2114 17.2454C3.80372 15.984 2.17758 13.6104 1.87147 10.9095H1.8716ZM4.5699 9.09158L1.8716 9.09073C2.17756 6.38981 3.80374 4.01617 6.21153 2.75483C5.18453 4.71255 4.62288 6.88073 4.57003 9.09073L4.5699 9.09158ZM9.09049 17.8786C7.77883 17.0272 6.56783 14.4293 6.38695 10.9095H9.09049V17.8786ZM9.09049 9.09071H6.38695C6.56764 5.57072 7.77874 2.973 9.09049 2.12164V9.09071ZM10.9084 17.8777L10.9093 10.9093H13.6128C13.4321 14.4293 12.221 17.027 10.9093 17.8784L10.9084 17.8777ZM10.9093 9.09071V2.12164C12.2209 2.97221 13.4319 5.57094 13.6128 9.09071H10.9093ZM13.7882 17.2454C14.8152 15.2877 15.3769 13.1195 15.4297 10.9095H18.128C17.8221 13.6104 16.1959 15.9841 13.7881 17.2454H13.7882ZM15.4297 9.09071C15.3769 6.88071 14.8152 4.71259 13.7882 2.75481C16.1959 4.01619 17.822 6.38979 18.1282 9.09071H15.4297Z" 
        fill={color}
      />
    </svg>
  </div>
)

export const TwitterIcon = ({ color = 'white' }) => (
  <div style={{ marginRight: '6px' }}>
      <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M25.3451 3.33906C24.5321 3.72383 23.6321 4.02497 22.732 4.19559C21.832 2.74017 20.333 1.71129 18.62 1.45536C18.3205 1.41186 18.0211 1.37004 17.7199 1.37004C14.8073 1.37004 12.3665 3.46787 11.9383 6.33882C11.8948 6.63827 11.853 6.98122 11.853 7.28066C8.51212 6.03937 5.77197 3.55507 4.22935 0.299454C4.14403 0.128814 3.97172 0.0418247 3.80108 0C3.63044 0 3.45813 0.0853197 3.3293 0.214134C2.34393 1.71308 2.088 3.51146 2.60158 5.18291C2.51626 5.18291 2.43094 5.13941 2.38745 5.18291C2.17331 5.2264 2.0445 5.35355 2.00267 5.56768C1.53089 7.36607 2.00267 9.29328 3.11686 10.7069C2.98804 10.7069 2.90272 10.7504 2.8174 10.8357C2.64676 10.9645 2.60327 11.1787 2.64676 11.3493C3.11855 13.1912 4.44515 14.6901 6.15823 15.3325C4.35984 15.9749 2.47443 16.2326 0.547048 16.1038C0.332913 16.1038 0.118779 16.2326 0.0334677 16.4467C-0.0518435 16.6609 0.0334676 16.875 0.204107 17.0456C2.13132 18.501 4.40148 19.4446 6.79859 19.8294C7.56982 19.9582 8.29753 20 9.06876 20C15.6632 20 21.4886 15.5884 23.2453 9.2496C23.3741 8.90665 23.4594 8.52188 23.5029 8.17892C23.5029 8.0936 23.5464 8.00828 23.5464 7.92129C23.5899 7.79248 23.5899 7.66367 23.6318 7.53652C24.6171 6.50936 25.4301 5.35166 26.0307 4.06686C26.1161 3.89622 26.0742 3.63859 25.9454 3.50977C25.7731 3.29731 25.5155 3.25381 25.3449 3.33913L25.3451 3.33906ZM18.6633 6.25163C18.1062 6.16631 17.7215 5.65274 17.8068 5.09564C17.8921 4.53855 18.4057 4.1538 18.9627 4.23911C19.5198 4.32443 19.9046 4.838 19.8193 5.39509C19.734 5.95218 19.2204 6.33694 18.6633 6.25163Z"
        fill={color}
      />
    </svg>
  </div>
)