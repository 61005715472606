import { ComponentProps, useRef, useEffect } from 'react';
import { useRive, useStateMachineInput } from 'rive-react';
import useOnScreen from '../folder/useOnScreen';
import styles from './Animations.module.css';

interface Props {
  graph: 'user-registrations' | 'monthly-teams' | 'activated-retention',
  xAxis: string[],
  yAxis: string[],
}

const GraphAnimation = ({ graph, xAxis, yAxis, ...rest }: Props & ComponentProps<'div'>) => {
  const params = {
    src: '/analytics.riv',
    artboard: graph,    
    stateMachines: 'controller',
    autoplay: true,
  }

  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const { rive, RiveComponent } = useRive(params);
  const input = useStateMachineInput(rive, 'controller', 'reveal');

  useEffect(() => {
    // input && isVisible === true && input.fire();
    if (input && isVisible === true) {
      setTimeout(() => {
        input.fire();
      }, 500);
    }
  }, [isVisible, input]);

  function reveal() {
    if (input) input.fire();
  }

  return (
    <div ref={ref} className={styles.square}>
      <RiveComponent className={styles.graph} {...rest} onClick={reveal} />
      <div className={styles.xAxis}>
        {xAxis.map((item) => (
          <p key={item}>{item}</p>
        ))}
      </div>
      <div className={styles.yAxis}>
        {yAxis.map((item) => (
          <div key={item}>
            <div className={styles.graphSegment} />
            <p>{item}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GraphAnimation;
