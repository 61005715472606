import { ComponentProps, useEffect } from 'react';
import { useRive, useStateMachineInput } from 'rive-react';

interface Props {
  activeCaption: string | null,
}

const SolutionAnimation = ({ activeCaption, ...rest }: Props & ComponentProps<'div'>) => {
  const stateMachine = 'State Machine 4';
  const params = {
    src: '/solution.riv',
    stateMachines: stateMachine,
    autoplay: true,
  }

  const { rive, RiveComponent } = useRive(params);
  const teamInput = useStateMachineInput(rive, stateMachine, 'team');
  const runtimeInput = useStateMachineInput(rive, stateMachine, 'runtime');
  const communityInput = useStateMachineInput(rive, stateMachine, 'community');
  const editorInput = useStateMachineInput(rive, stateMachine, 'editor');

  useEffect(() => {
    function trigger(section: string) {
      switch (section) {
        case 'Team': return teamInput?.fire()
        case 'Runtime': return runtimeInput?.fire();
        case 'Community': return communityInput?.fire();
        case 'Editor': return editorInput?.fire();
      }
    }
    activeCaption && trigger(activeCaption);
  }, [activeCaption, teamInput, runtimeInput, communityInput, editorInput]);

  return <RiveComponent {...rest} />;
};

export default SolutionAnimation;
