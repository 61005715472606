import { forwardRef, useEffect, useState } from 'react';
import Slide from '../global/Slide';
import RiveIcon from '../global/RiveIcon';

import HouseCards from '../animations/HouseCards';

import styles from './Problem.module.css';

const content = [
  {
    icon: 'icons/icon_digital.riv',
    caption: 'Most motion graphic tools are designed for broadcast – not realtime digital experiences.'
  },
  {
    icon: 'icons/icon_limited.riv',
    caption: 'Product design tools often have limited animation functionality, if any.'
  },
  {
    icon: 'icons/icon_box.riv',
    caption: 'Game development tools are complex and require specific engines to run.'
  },
  {
    icon: 'icons/icon_video.riv',
    caption: 'The process of designing and implementing animation is slow and prone to inconsistencies.'
  },
]

const Problem = forwardRef((_, ref) => {
  const [hoverIndex, setHoverIndex] = useState(null);
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    if (collapse) setTimeout(() => setCollapse(false), 7000);
  }, [collapse])
  
  return (
    <Slide ref={ref} className={styles.problem}>
      <div className={styles.rivContainer}>
        <div className={styles.ratio}>
          <HouseCards className={styles.animation} />
        </div>
      </div>
      <div className={styles.copyContainer}>
        <div className={styles.copy}>
          <h3>The problem</h3>
          <h2>In 2021, animating digital experiences is still cumbersome</h2>
          {content.map((item, index) => (
            <div
              key={`problem-item-${index}`}
              className={styles.callout}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              <div className={styles.iconContainer}>
                <RiveIcon file={item.icon} isHovering={index === hoverIndex} />
              </div>
              <p>{item.caption}</p>
            </div>
          ))}
        </div>
      </div>
    </Slide>
  );
})

export default Problem;