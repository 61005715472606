import {
  useRive,
  useStateMachineInput,
  Layout,
  Fit,
  Alignment,
} from 'rive-react';
import { useEffect } from 'react';
import RiveSlider from '../rive-ui/RiveSlider';
import styles from './Animations.module.css';

const SeaLevelAnimation = () => {
  const { rive, RiveComponent } = useRive({
    src: '/water-bar.riv',
    stateMachines: 'State Machine',
    autoplay: true,
    layout: new Layout({
      alignment: Alignment.Center,
      fit: Fit.Cover,
    }),
  });

  const levelInput = useStateMachineInput(rive, 'State Machine', 'Level');

  useEffect(() => {
    if (levelInput) {
      levelInput.value = 50;
    }
  }, [levelInput]);

  function handleSlider(value: number) {
    if (levelInput) {
      levelInput.value = value;
    }
  }

  return (
    <div>
      <div className={styles.container}>
        <RiveComponent className={styles.visual} />
      </div>
      {levelInput && (
        <RiveSlider
          onValueChange={handleSlider}
          initialValue={levelInput ? (levelInput.value as number) : 0}
        />
      )}
    </div>
  );
};

export default SeaLevelAnimation;
