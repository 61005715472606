import { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import Modal from './Modal';

const Expandable = ({ className, children, modalHeader, modalCopy }) => {
  const modalRef = useRef(null);
  const nodeRef = useRef(null);
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    document.body.style.overflow = showModal ? 'hidden' : 'auto';

    const close = (event) => {
      if (showModal && !modalRef.current?.contains(event.target)) {
        setShowModal(false)
      }
    };

    showModal
      ? window.addEventListener('click', close, true)
      : window.removeEventListener('click', close, true);
  }, [showModal]);

  function show() {
    // setShowModal(true);
  }

  return (
    <>
      <div className="expandable" onClick={show}>
        <div className={className}>
          {children}
        </div>
      </div>
      <CSSTransition
        nodeRef={nodeRef}
        in={showModal}
        classNames="modal-transition"
        unmountOnExit
        timeout={250}
      >
        <Modal 
          showing={showModal} 
          ref={modalRef} 
          nodeRef={nodeRef}
          header={modalHeader}
          copy={modalCopy}
        />
      </CSSTransition>
    </>
  );
};

export default Expandable;