import { forwardRef } from 'react';

const Modal = forwardRef(({ onClick, nodeRef, header, copy }, ref) => (
  <div 
    ref={nodeRef} 
    className="modal-container" 
    onClick={onClick}
  >
    <div ref={ref} className="modal">
      <h2>{header}</h2>
      <p>{copy}</p>
    </div>
  </div>
))

export default Modal;