import { FunctionComponent } from 'react';
import ReactPlayer from 'react-player/youtube';
import styles from './VideoOverlay.module.css';

type VideoOverlayProps = {
  onClose: () => void;
};

const VideoOverlay: FunctionComponent<VideoOverlayProps> = ({ onClose }) => {
  return (
    <div className={styles.Overlay} onClick={onClose}>
      <button className={styles.Close} onClick={onClose}>
        <img src="/static/close.svg" alt="Close" />
      </button>
      <div className={styles.EpisodePlayer}>
        <div className={styles.PlayerWrapper}>
          <ReactPlayer
            className={styles.ReactPlayer}
            url="https://youtu.be/inNaaPYsRCU"
            controls
            playing
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
};

export default VideoOverlay;
