import { forwardRef } from 'react';

// const Slide = forwardRef(({ className, children }, ref) => (
//   <>
//     <div ref={ref} className="slide">
//       <div className="aspect-ratio-16x9">
//         <div className={`slide-content ${className}`}>
//           {children}
//         </div>
//       </div>
//     </div>
//     <div className="divider" />
//   </>
// ));

const Slide = forwardRef(({ className, children, hideDivider = false }, ref) => (
  <>
    <div ref={ref} className={`slide ${className}`}>
      {children}
    </div>
    {!hideDivider && <div className="divider" />}
  </>
));

export default Slide;