import { forwardRef, useState, useEffect } from "react";
import { YouTubeIcon, WebIcon, TwitterIcon } from "../global/Icons";
import Slide from "../global/Slide";
import NeoMenu from "../animations/NeoMenu";
import RiveMnemonic from "../animations/RiveMnemonic";
import VideoOverlay from "../global/VideoOverlay";

import styles from "./Cover.module.css";

const Cover = forwardRef(({ onClick }, ref) => {
  const [revealMnemonic, setRevealMnemonic] = useState(false);
  const [videoOverlay, setVideoOverlay] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRevealMnemonic(true);
    }, 2000);
  }, []);

  return (
    <Slide ref={ref} className={styles.cover}>
      {videoOverlay && <VideoOverlay onClose={() => setVideoOverlay(false)} />}
      <NeoMenu className={styles.animationContainer} onFlameClick={onClick} />
      <div className={styles.copyContainer}>
        <RiveMnemonic className={styles.mnemonic} play={revealMnemonic} />
        {window.innerWidth > 768 ? (
          <h1>Series A fundraise</h1>
        ) : (
          <>
            <h1>Series A fundraise</h1>
            <div className={styles.spacer} />
          </>
        )}
        <div className={styles.linkContainer}>
          <IconLink
            icon={icons.YOUTUBE}
            onYouTubeClick={() => setVideoOverlay(true)}
          />
          <IconLink icon={icons.WEB} />
          <IconLink icon={icons.TWITTER} />
        </div>
      </div>
    </Slide>
  );
});

export default Cover;

const icons = {
  WEB: "Website",
  TWITTER: "Twitter",
  YOUTUBE: "Intro",
};

const IconLink = ({ icon, onYouTubeClick }) => {
  const [isHovering, setIsHovering] = useState(false);
  const link = () => {
    switch (icon) {
      case icons.YOUTUBE:
        return "https://www.youtube.com/channel/UCPal2R1FxwRTPylhP_7ofEg";
      case icons.TWITTER:
        return "https://twitter.com/rive_app";
      default:
        return "https://rive.app";
    }
  };

  if (icon === icons.YOUTUBE) {
    return (
      <button
        onClick={onYouTubeClick}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <YouTubeIcon color={isHovering ? "white" : "#666666"} />
        {icon}
      </button>
    );
  }

  return (
    <a
      href={link()}
      target="_blank"
      rel="noopener noreferrer"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {icon === icons.YOUTUBE && (
        <YouTubeIcon color={isHovering ? "white" : "#666666"} />
      )}
      {icon === icons.WEB && (
        <WebIcon color={isHovering ? "white" : "#666666"} />
      )}
      {icon === icons.TWITTER && (
        <TwitterIcon color={isHovering ? "white" : "#666666"} />
      )}
      {icon}
    </a>
  );
};
