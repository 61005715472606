import { forwardRef } from 'react';
import Slide from '../global/Slide';
import Rive from 'rive-react';

import styles from './Runtimes.module.css';

const content = {
  benefits: [
    {
      animation: 'runtimes_size.riv',
      title: 'Optimized for size',
      caption: 'We recognize the importance of keeping file sizes to a minimum'
    },
    {
      animation: 'runtimes_control.riv',
      title: 'Designed for control',
      caption: 'We provide high and low level runtimes to offer both simplicity and control'
    },
    {
      animation: 'runtimes_opensource.riv',
      title: 'Entirely open-source',
      caption: 'Runtimes are transparent and welcome community contributions'
    },
  ],
  runtimes: [
    {
      title: 'Web',
      logo: '/runtimes/logo_wasm.svg',
      url: 'https://www.npmjs.com/package/rive-canvas'
    },
    {
      title: 'Flutter',
      logo: '/runtimes/logo_flutter.svg',
      url: 'https://pub.dev/packages/rive'
    },
    {
      title: 'React',
      logo: '/runtimes/logo_react.svg',
      url: 'https://github.com/rive-app/rive-react-native'
    },
    {
      title: 'C++',
      logo: '/runtimes/logo_cpp.svg',
      url: 'https://github.com/rive-app/rive-cpp'
    },
    {
      title: 'iOS',
      logo: '/runtimes/logo_apple.svg',
      url: 'https://github.com/rive-app/rive-ios'
    },
    {
      title: 'Android',
      logo: '/runtimes/logo_android.svg',
      url: 'https://github.com/rive-app/rive-android'
    },
    {
      title: 'React Native',
      logo: '/runtimes/logo_react.svg',
      url: 'https://github.com/rive-app/rive-react-native'
    },
    {
      title: 'Defold',
      logo: '/runtimes/logo_defold.svg',
      url: 'https://forum.defold.com/t/rive-support-alpha-testing/69115'
    },
    {
      title: 'Tizen',
      logo: '/runtimes/logo_tizen.svg',
      url: 'https://github.com/rive-app/rive-tizen'
    },
    {
      title: 'Unity',
      logo: '/runtimes/logo_unity.svg'
    }
  ],
}

const Runtimes = forwardRef((_, ref) => (
  <Slide ref={ref} className={styles.runtimes}>
    <div className={styles.header}>
      <div>
        <h3>Our runtimes</h3>
        <h2>Deploy to the web, products, and games</h2>
        <p>Our open-source runtimes provide users with full control across devices and platforms.</p>
      </div>
      <EcosystemAnimation />
    </div>
    <div className={styles.platforms}>
      {content.runtimes.map((r) => (
        <RuntimeIcon key={r.title} {...r} />
      ))}
    </div>
    <div className={styles.runtimeBenefits}>
      {content.benefits.map(benefit => (
        <div className={styles.benefit} key={benefit.title}>
          <div className={styles.ratio}>
            <Rive className={styles.benefitAnimation} src={benefit.animation} />
          </div>
          <div>
            <h4>{benefit.title}</h4>
            <p>{benefit.caption}</p>
          </div>
        </div>
      ))}
    </div>
  </Slide>
))

export default Runtimes;

const EcosystemAnimation = () => (
  <div className={styles.ecosystemContainer}>
    <img
      className={styles.runtimeDevicesImg}
      src="ecosystem_frame.png"
      alt="Multiplatform"
    />
    <Rive
      className={styles.ecosystemAnimation}
      src="/runnertime_boy.riv"
    />
  </div>
);

const RuntimeIcon = ({ title, logo, url }) => (
  <div
    className={styles.runtimeTile}
  >
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className={styles.runtimeLogo} src={logo} alt="Runtime logo" />
      <p>{title}</p>
    </a>
  </div>
);