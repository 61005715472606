import { forwardRef } from 'react';
import Slide from '../global/Slide';
import Rive, { Layout, Alignment, Fit } from 'rive-react';

import styles from './Market.module.css';

const content = [
  {
    heading: 'Web',
    animation: '/tv.riv',
    caption: 'Nowadays, the web is more powerful than ever. We embraced standards and made insane technological advancements, but something creative has disappeared. Sites follow templates and patterns that make everything feel like they are part of the same brand. The few that stand out are an exception and require individuals willing to put in extraordinary effort. Rive provides the tools to drive creativity and make it possible for everyone to build unique and outstanding content for the web.',
  },
  {
    heading: 'Products',
    animation: '/dash.riv',
    caption: 'Flash was growing as a tool not just for web experiences but for all product categories (desktop, mobile, tablets, car dashboards, smart TVs, fridges, you name it). Creators still need a tool that visually drives the design, interactivity, and animation of a product. But they do not want to be locked to a single app engine (e.g. the Flash plugin). App engines and technologies like Swift, Flutter, React, Rust, and Wasm may come and go, and Rive will continue to support the latest and greatest thanks to our open-source runtimes.',
  },
  {
    heading: 'Games',
    animation: '/mountain.riv',
    caption: 'Creating menus and heads-up displays (HUDs) for games requires extraordinary effort. Flash was an integral part of this market thanks to <a href="https://www.autodesk.com/products/scaleform/features/all/gallery-view">Scaleform</a> (<a href="https://en.wikipedia.org/wiki/Scaleform_GFx">discontinued</a>), a middleware package that allowed Flash vector graphics to work with game engines. The <a href="https://en.wikipedia.org/wiki/List_of_games_using_Scaleform">list of games launched with Scaleform</a> from 2003 to today is tremendous. Not only can Rive serve the 2D gamedev market (for creating characters, backgrounds, and props for titles like Rayman, Cuphead, and Candy Crush), but it can also serve the larger 3D gamedev market. From AAA games like Halo and Destiny to VR titles like Half-Life: Alyx, they all need animated UIs, menus, and HUDs.',
  },
]

const Market = forwardRef((_, ref) => {
  return (
    <Slide ref={ref} className={styles.market}>
      <h3>Market</h3>
      <h2>Realtime animation in a post Flash world</h2>
      <p>Over a decade on from the Flash era, over <a href="https://techcrunch.com/2010/01/10/flash-developers-iphone/">2 million designers and developers</a> are still without a compelling alternative</p>
      <div className={styles.threeUp}>
        {content.map(item => (
          <div key={item.heading}>
            <div className={styles.ratio}>
              <Rive 
                className={styles.featureAnimation}
                src={item.animation}
                layout={new Layout({
                  fit: Fit.Cover,
                  alignment: item.heading === 'Games' ? Alignment.TopLeft : Alignment.Center
                })}
              />
            </div>
            <h3>{item.heading}</h3>
            <div className={styles.divider}/>
            <p dangerouslySetInnerHTML={{ __html:item.caption}}></p>
          </div>
        ))}
      </div>
    </Slide>
  )
})

export default Market;